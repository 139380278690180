<template>
    <div>
        <fa-icon :icon="['fas', 'pen-to-square']" @click="openEditForm"/>
        <Kepler_Form
            v-if="open && value"
            v-model="updateInfo"
            :modalId="value._id"
            :formTitle="'Edit Site'"
            @sendObject="sendData"
            @askToDelete="askToDelete">
        </Kepler_Form>
    </div>
</template>

<script>

import Kepler_Form from "@/views/admin/kepler/Kepler_Form";
import Vue from "vue";

export default {
    name: "Kepler_Update",
    components: {
        Kepler_Form
    },
    props: {
        value: Object
    },
    watch: {
        value: function() {
            this.updateInfo = Object.assign({}, this.value);
        }
    },
    data() {
      return {
          updateInfo: Object.assign({}, this.value),
          open: false,
      }
    },
    methods: {
        openEditForm() {
            this.open = true;
            Vue.nextTick().then(() => {
                this.$root.$emit("bv::toggle::modal", this.value._id, "#kepler");
            });
        },
        sendData(updatedInfo) {
            this.$emit("sendData", updatedInfo);
        },
        askToDelete(object) {
            this.$emit("askToDelete", object);
        }
    },
}
</script>

<style scoped>
</style>