<template>
    <div>
        <fa-icon :icon="['fas', 'square-plus']" @click="openEditForm"></fa-icon>
        <Kepler_Form
            v-if="open && value"
            v-model="value"
            :modalId="newId"
            :formTitle="'Site Creator'"
            @sendObject="sendData">
        </Kepler_Form>
    </div>
</template>

<script>

import Kepler_Form from "@/views/admin/kepler/Kepler_Form";
import Vue from "vue";

export default {
    name: "Kepler_Create",
    components: {
        Kepler_Form
    },
    props: {
        value: Object
    },
    data() {
        return {
            open: false,
            newId: "newId"
        }
    },
    methods: {
        openEditForm() {
            this.open = true;
            Vue.nextTick().then(() => {
                this.$root.$emit("bv::toggle::modal", this.newId, "#kepler");
            });
        },
        sendData(updatedInfo) {
            this.$emit("sendData", updatedInfo);
        }
    },
    created() {

    }
}
</script>

<style scoped>

</style>