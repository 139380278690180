<template>
    <b-modal :title="formTitle" :id="modalId" no-close-on-backdrop @close="closeModal">
        <div class="title">Kepler Configuration</div>

        <b-form-group label="Stargate IP:" label-for="ipaddress">
            <b-form-input
                :class="formData.ipaddress === '' ? 'border border-danger' : ''"
                placeholder="333.22.22.22"
                id="ipaddress"
                v-model="formData.ipaddress">
            </b-form-input>
        </b-form-group>

        <b-form-group label="Docker Port:" label-for="port">
            <b-form-input
                :class="formData.port === '' ? 'border border-danger' : ''"
                placeholder="4444"
                id="port"
                v-model="formData.port"></b-form-input>
        </b-form-group>

        <b-form-group label="Replicas:" label-for="replicas">
            <b-form-input
                :class="formData.replicas === '' ? 'border border-danger' : ''"
                placeholder="1"
                id="replicas"
                v-model="formData.replicas"
                type="number"></b-form-input>
        </b-form-group>

        <div class="title">Spaceport Configuration</div>

        <b-form-group label="Site Name:" label-for="sitename">
            <b-form-input
                :class="formData.sitename === '' ? 'border border-danger' : ''"
                placeholder="Site Name"
                id="sitename"
                v-model="formData.sitename"></b-form-input>
        </b-form-group>

        <b-form-group label="Space Port:" label-for="spaceportport">
            <b-form-input
                :class="formData.spaceportport === '' ? 'border border-danger' : ''"
                placeholder="4444"
                id="spaceportport"
                v-model="formData.spaceportport"></b-form-input>
        </b-form-group>

        <b-form-group label="MongoURL:" label-for="mongourl">
            <b-form-input
                :class="formData.mongourl === '' ? 'border border-danger' : ''"
                placeholder="mongodb://333.22.22.22:55555/parameter"
                id="mongourl"
                v-model="formData.mongourl"></b-form-input>
        </b-form-group>

        <b-form-group label="Secret Key:" label-for="secretkey">
            <b-form-input
                :class="formData.secretkey === '' ? 'border border-danger' : ''"
                placeholder="Secret Key"
                id="secretkey"
                v-model="formData.secretkey"></b-form-input>
        </b-form-group>

        <b-form-group label="Redis Port:" label-for="redisPort">
            <b-form-input
                :class="formData.redisPort === '' ? 'border border-danger' : ''"
                placeholder="4444"
                id="redisPort"
                v-model="formData.redisPort"></b-form-input>
        </b-form-group>

        <b-form-group label="Mount Point:" label-for="mountPoint">
            <b-form-input
                :class="formData.mountPoint === '' ? 'border border-danger' : ''"
                placeholder="/mount/spaceport"
                id="mountPoint"
                v-model="formData.mountPoint"></b-form-input>
        </b-form-group>

        <b-form-group label="Local IP:" label-for="localIp">
            <b-form-input
                :class="formData.localIp === '' ? 'border border-danger' : ''"
                placeholder="333.22.22.22"
                id="localIp"
                v-model="formData.localIp"></b-form-input>
        </b-form-group>

        <b-form-group label="Public URL:" label-for="stargateIp">
            <b-form-input
                :class="formData.stargateIp === '' ? 'border border-danger' : ''"
                placeholder="Public URL"
                id="stargateIp"
                v-model="formData.stargateIp"></b-form-input>
        </b-form-group>

        <b-form-group label="Public Port:" label-for="stargatePort">
            <b-form-input
                :class="formData.stargatePort === '' ? 'border border-danger' : ''"
                placeholder="4444"
                id="stargatePort"
                v-model="formData.stargatePort"></b-form-input>
        </b-form-group>

        <div v-if="value.created_date">
            <b-button style="width: 100%" variant="danger" @click="askToDelete">Delete Site</b-button><br><br>
            <div class="dateInfo">Date Created: {{readableDate}}</div>
        </div>
        <template slot="modal-footer">
            <b-button class="btn-sm" @click="closeModal">Cancel</b-button>
            <b-button class="btn btn-primary btn-sm btn" variant="primary" :disabled="isReady()" @click="returnObject">Submit</b-button>
        </template>



    </b-modal>
</template>

<script>
export default {
    name: "Kepler_Form",
    props: {
        value: Object,
        modalId: String,
        formTitle: String,
    },
    created() {
        if(this.formData.created_date) {
            this.makeDate(this.formData.created_date);
        }
    },
    watch: {
        value: function() {
            this.formData = Object.assign({}, this.value)
        }
    },
    data() {
        return {
            readableDate: "",
            formData:  Object.assign({}, this.value),
        }
    },
    methods: {
        closeModal() {
            this.$bvModal.hide(this.modalId);
            this.formData =  Object.assign({}, this.value);
        },
        returnObject() {
            this.$emit("sendObject", this.formData);
            this.$bvModal.hide(this.modalId);
            if(!this.value._id){
                this.formData = Object.assign({}, this.value);
            }
        },
        askToDelete() {
          this.$emit("askToDelete", this.value);
        },
        makeDate(time) {
            let created_date = time;
            let date = [];
            let months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            date.push(created_date.substring(0, 4));
            date.push(created_date.substring(5, 7));
            date.push(created_date.substring(8, 10));
            date.push(created_date.substring(11, 16));
            let mlong = months[parseInt(date[1])];

            function tConvert(time) {
                time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                if (time.length > 1) {
                    time = time.slice(1);
                    time[5] = +time[0] < 12 ? 'AM' : 'PM';
                    time[0] = +time[0] % 12 || 12;
                }
                return time.join('');
            }

            date[3] = tConvert(date[3])
            this.readableDate = mlong + " " + date[2] + ", " + date[0] + " at " + date[3] + " UTC"
        },
        isReady() {
            return this.formData.ipaddress === "" || this.formData.port === "" || this.formData.replicas === "" ||
                this.formData.sitename === "" || this.formData.spaceportport === "" || this.formData.mongourl === "" ||
                this.formData.secretkey === "" || this.formData.redisPort === "" || this.formData.mountPoint === "" ||
                this.formData.localIp === "" || this.formData.stargateIp === "" || this.formData.stargatePort === "";
        }
    },
}
</script>

<style scoped>
.title {
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
}

.dateInfo {
    text-align: center;
    font-size: 14px;
    color: #727272;
}
</style>