<template>
    <div>
        <div>
            <b-modal no-close-on-backdrop :title="'Confirm Delete'" class="modal-dark" v-model="deleteModal"
                     ok-variant="danger" @close="resetCloseModal">
                <p>If you would like to delete this please type the name below.</p>
                <br>
                <p>Enter "{{deleteModalName.sitename}}":</p>
                <b-form-group description="case sensitive">
                    <b-form-input v-model="nameVerification" type="text"></b-form-input>
                </b-form-group>
                <template slot="modal-footer">
                    <b-button size="sm" variant="secondary" @click="resetCloseModal">Cancel</b-button>
                    <b-button :disabled="nameVerification !== deleteModalName.sitename" size="sm" variant="danger"
                              @click="submitDeleteModal(deleteModalName._id)">Delete
                    </b-button>
                </template>
            </b-modal>

            <b-card style="width: 100%; height: 100%;">
                <div slot="header">
                    <span class="titleSpacing">
                        <div class="title">Kepler</div>
                        <Kepler_Create v-model="configSkeleton" @sendData="submitCreateModal"></Kepler_Create>
                    </span>
                </div>

                <v-client-table :data="sites" :columns="columns" :options="options" ref="myTable" style="width: 100%; " class="dataTable">
                    <div slot="sitename" slot-scope="props">
                        <b-badge :variant="getBadge(props.row)">{{ props.row.sitename }}</b-badge>
                    </div>
                    <div slot="edit" slot-scope="props">
                        <Kepler_Update v-model="props.row" @sendData="submitEditModal" @askToDelete="showDeleteModal"></Kepler_Update>
                    </div>
                    <div slot="start" slot-scope="props">
                        <b-button @click="startSite(props.row)" variant="success" size="sm">Start</b-button>
                    </div>
                    <div slot="prune" slot-scope="props">
                        <b-button @click="pruneSite(props.row)" variant="info" size="sm">Prune</b-button>
                    </div>
                </v-client-table>
            </b-card>
        </div>
    </div>
</template>

<script>
import fcr from '../../../services/fcr.js'
import TestComponent from '../Component.vue'
import Kepler_Update from "@/views/admin/kepler/Kepler_Update";
import Kepler_Create from "@/views/admin/kepler/Kepler_Create";
import {ClientTable} from 'vue-tables-2';
import Vue from "vue";

export default {
    components: {
        Kepler_Update,
        Kepler_Create,
        TestComponent,
        ClientTable
    },
    data: () => {
        return {
            sites: [],
            errorMsg: {method: "", status: "", statusText: ""},
            dockerInfo: {},
            columns: ['sitename', 'ipaddress', 'port', 'replicas', 'mongourl', 'secretkey', 'redisPort', 'mountPoint',
                'localIp', 'spaceportport', 'stargateIp', 'stargatePort', 'edit', 'start', 'prune'],
            options: {
                orderBy: {column: 'sitename', ascending: true},
                headings: {
                    sitename: 'Site Name',
                    ipaddress: 'Stargate IP',
                    port: 'Docker Port',
                    replicas: 'Replicas',
                    mongourl: 'Mongo URL',
                    secretkey: 'Secret Key',
                    redisPort: 'Redis Port',
                    mountPoint: 'Mount Point',
                    localIp: 'Local IP',
                    spaceportport: 'Spaceport Port',
                    stargateIp: 'Public URL',
                    stargatePort: 'Public Port'
                },
                sortable: ['sitename', 'ipaddress', 'port', 'replicas', 'mongourl', 'secretkey', 'redisPort',
                    'mountPoint', 'localIp', 'spaceportport', 'stargateIp', 'stargatePort',],
                filterable: ['sitename', 'ipaddress', 'port', 'replicas', 'mongourl', 'secretkey', 'redisPort',
                    'mountPoint', 'localIp', 'spaceportport', 'stargateIp', 'stargatePort',],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            configSkeleton: {
                ipaddress: "10.69.0.",
                port: "2375",
                replicas: "",
                sitename: "",
                spaceportport: "3033",
                mongourl: "",
                secretkey: "montel",
                redisPort: "6379",
                mountPoint: "",
                localIp: "",
                stargateIp: "",
                stargatePort: "443",
            },
            deleteModal: false,
            deleteModalName: {},
            nameVerification: "",
        }
    },
    created() {
        this.getKeplerSites(true);
    },

    methods: {
        showDeleteModal(object) {
            this.deleteModal = true;
            this.deleteModalName = object;
        },
        resetCloseModal() {
            this.deleteModalName = this.nameVerification = "";
            this.deleteModal = false;
        },
        getKeplerSites() {
            fcr.getKeplerSites().then(response => {
                this.sites = response.data;
                this.$emit('loaded');
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Sites couldn't be retrieved: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        submitEditModal(site) {
            fcr.updateKeplerSite(site).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: "Site updated",
                    style: 'success'
                });
                this.getKeplerSites();
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Site couldn't be edited: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        submitDeleteModal(id) {
            fcr.deleteKeplerSite(id).then(() => {
                this.getKeplerSites();
                this.resetCloseModal();
                this.$root.$emit("bv::toggle::modal", id, "#kepler");
                this.$mToast({
                    title: 'Success',
                    text: "Site deleted",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Endpoint couldn't be deleted: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        submitCreateModal(site) {
            fcr.createKeplerSite(site).then(() => {
                this.getKeplerSites();
                this.$mToast({
                    title: 'Success',
                    text: "Site created",
                    style: 'success'
                });
            }).catch(error => {
              this.$mToast({
                title: error.response.status + ' Error',
                text: "Site couldn't be created: " + error.response.statusText,
                style: 'error'
              });
            })
        },
        startSite(site) {
            fcr.keplerStart(site._id).then(response => {
                this.getKeplerSites();
                this.$mToast({
                    title: 'Success',
                    text: "Site started",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Endpoint couldn't be started: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        pruneSite(site) {
            fcr.keplerPrune(site._id).then(() => {
                this.getKeplerSites();
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Site couldn't be pruned: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        getBadge(status) {
            return status.status === 'inactive' ? 'danger'
                : (status.status) === 'active' ? 'success' : 'success'
        },
    }
}
</script>

<style scoped>
.title {
    font-size: 16px;
    font-weight: bold;
}

.titleSpacing {
    display: flex;
    justify-content: space-between;
}

</style>